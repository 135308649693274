const splitStr = (str) => {
  const half = str.length / 2;
  const obj = {
    start: [],
    end: [],
  };

  str.split(" ").forEach((word) => {
    if (obj.start.length && (obj.end.length || obj.start.join(" ").length + word.length > half)) {
      obj.end.push(word);
    } else {
      obj.start.push(word);
    }
  });

  return {
    start: obj.start.join(" ").replace("\\n", ""),
    end: obj.end.join(" ").replace("\\n", ""),
  };
};

export default splitStr;
