import React from "react";
import Layout from "../../components/Layout/Layout";
import BenefitsSection from "../../components/BenefitsSection/BenefitsSection";
import GrowingInNandosSection from "../../components/GrowingInNandosSection/GrowingInNandosSection";
import { BenefitsContent } from "../../components/BenefitsSection/BenefitsSectionContent";
import WhyNandosSubTitleSection from "../../components/WhyNandosSubTitleSection/WhyNandosSubTitleSection";
import { WhyNandosSubtitleSection } from "../../components/WhyNandosSubTitleSection/WhyNandosSubTitleSectionContent";
import { GrowingInNandosContent } from "../../components/GrowingInNandosSection/GrowingInNandosSectionContent";
import { SEO } from "../../components/seo";
import { descriptions } from "../../libs/seoContent.js";

const WhyNandos = () => {
  return (
    <Layout>
      <WhyNandosSubTitleSection content={WhyNandosSubtitleSection} />
      <BenefitsSection content={BenefitsContent} bottomRect></BenefitsSection>
      <GrowingInNandosSection content={GrowingInNandosContent} />
    </Layout>
  );
};

export default WhyNandos;

export const Head = () => <SEO title="Why Nando's" description={descriptions.whyNandos} />;
