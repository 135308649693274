import * as React from "react";
import splitStr from "../../libs/helpers/splitString";

import "./BannerTitle.scss";

type Props = {
  title: string;
  margin?: boolean;
  textSplit?: string[];
};

const BannerTitle = ({ title, margin, textSplit }: Props) => {
  let { start, end } = splitStr(title);
  if (textSplit && textSplit.length > 0) {
    start = textSplit[0];
    end = textSplit[1];
  }

  return (
    <h1 className="banner-title">
      <span className={"mobile-bg-red " + (margin ? "banner-margin" : "")}>{start}</span>
      <br />
      <span className="bg-red">{end}</span>
    </h1>
  );
};

export default BannerTitle;
