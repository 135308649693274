import WhyNandosImageDesktop from "../../assets/img/whyNandosPageAssets/WhyNandosMainImage.png";
import WhyNandosImageMobile from "../../assets/img/whyNandosPageAssets/WhyNandosSubtitleMobile.png";
import WhyNandosContentImage from "../../assets/img/whyNandosPageAssets/WhyNandosSubtitleImage.png";
import WhyNandosContentImageMobile from "../../assets/img/whyNandosPageAssets/WhyNandosContentImageMobile.png";
import awardWinner from "../../assets/img/homePageAssets/awardWinner.png";

type TWhyNandosSubtitleContent = {
  bannerTitleText: string;
  backgroundImage: string;
  backgroundImageMobile: string;
  contentImage: string;
  contentImageMobile: string;
  awardWinner: string;
  route: string;
  label: string;
  subTitleText: string;
  subTitleDescription: string;
};

export const WhyNandosSubtitleSection: TWhyNandosSubtitleContent = {
  bannerTitleText: "Why Nando's",
  backgroundImage: WhyNandosImageDesktop,
  backgroundImageMobile: WhyNandosImageMobile,
  contentImage: WhyNandosContentImage,
  contentImageMobile: WhyNandosContentImageMobile,
  awardWinner: awardWinner,
  route: "/our-roles",
  label: "View Our Roles",
  subTitleText: "We've won awards for being a great place to work",
  subTitleDescription:
    "Why Nando's? That’s a good question, but also a question with no definitive answer. In fact, if you asked everyone who worked for us, you’d probably get a different answer every time. For some it may be the fact that we’re the kind of name that’s on everyone’s lips. For others it may be the fact that we’ve won awards for being a great place to work and continually invest in our people. To be on the safe side, we’ll go over a few of the reasons why people join us.",
};
