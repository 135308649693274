import * as React from "react";
import leftPattern from "../../assets/img/restaurantTeamMemberPageAssets/Left.svg";
import rightPattern from "../../assets/img/patterns/Testimonial_Right_Pattern.svg";
import bottomLeftPattern from "../../assets/img/patterns/Testimonial_Left_Pattern.svg";
import rightPatternUpperRightMobile from "../../assets/img/restaurantTeamMemberPageAssets/UpperRightMobile.svg";
import rightPatternLower from "../../assets/img/restaurantTeamMemberPageAssets/LowerRight.svg";
import AnimationButton from "../AnimationButton/AnimationButton";
import BannerTitle from "../BannerTitle/BannerTitle";
import "./WhyNandosSubTitleSection.scss";

type Props = {
  content: TWhyNandosSubtitleContent;
};

const WhyNandosSubtitleSection = ({ content }: Props) => {
  return (
    <div className="why-nandos-subtitle-section">
      <div className="why-nandos-subtitle">
        <img
          src={content.backgroundImage}
          aria-label="whyNandosSubtitleImage"
          className="why-nandos-subtitle-img desktop-only"
        />
        <img
          src={content.backgroundImageMobile}
          aria-label="whyNandosSubtitleMobileImage"
          className="why-nandos-subtitle-img mobile-only"
        />
        <BannerTitle title={content.bannerTitleText} />
      </div>
      <img
        src={leftPattern}
        aria-label="whyNandosLeftPattern"
        className="why-nandos-left-pattern no-pointer"
      />
      <img
        src={rightPatternUpperRightMobile}
        aria-label="whyNandosUpperRightPattern"
        className="why-nandos-upper-right-pattern mobile-only"
      />
      <img
        src={rightPatternLower}
        aria-label="whyNandosRightPatternLower"
        className="why-nandos-right-pattern-lower mobile-only"
      />
      <div className="why-nandos-content">
        <div className="why-nandos-subtitle-text">
          <h2>{content.subTitleText}</h2>
        </div>
        <div className="why-nandos-content-container">
          <div className="column">
            <img src={content.contentImage} alt="whyNandosSubtitle" className="why-nandos-img" />
            <img
              src={content.contentImageMobile}
              alt="whyNandosContent"
              className="why-nandos-img-mobile mobile-only"
            />
          </div>
          <div className="column">
            <p>{content.subTitleDescription}</p>
            <div className="why-nandos-button-container">
              <AnimationButton route={content.route} label={content.label} color="#ffcd41" />
            </div>
          </div>
          <img
            src={rightPattern}
            aria-label="whyNandos-right-pattern"
            className="why-nandos-right-pattern no-pointer"
          />
          <img
            src={bottomLeftPattern}
            aria-label="whyNandos-bottom-left-pattern"
            className="why-nandos-bottom-left-pattern no-pointer"
          />
        </div>
      </div>
    </div>
  );
};

export default WhyNandosSubtitleSection;
