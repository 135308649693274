import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import TitleWithAccents from "../TitleWithAccents/TitleWithAccents";
import classNames from "classnames";
import AnimationButton from "../AnimationButton/AnimationButton";
import topRightPattern from "../../assets/img/patterns/Benefits_Top_Right_Pattern.svg";
import bottomLeftPattern from "../../assets/img/patterns/Benefits_Bottom_Left_Pattern.svg";
import { NandosCarousel } from "../NandosCarousel/NandosCarousel";

import "./BenefitsSection.scss";

type Props = {
  content: TBenefitsSectionContent;
  rect?: boolean;
  topRect?: boolean;
  bottomRect?: boolean;
};

const BenefitsSection = ({ content, rect = false, topRect = false, bottomRect = false }: Props) => {
  return (
    <div
      className={classNames("benefits-section-container", {
        rect,
        "bottom-rect": bottomRect,
        "top-rect": topRect,
      })}
    >
      <img
        src={topRightPattern}
        aria-label="top-right-pattern"
        className="benefits-top-right-pattern no-pointer"
      />
      {/* Section title */}
      <TitleWithAccents title={content.title} />

      {/* Carousel for mobile */}
      <p className="benefits-description-paragraph mobile-only">{content.description}</p>
      <div className="carousel-mobile-only">
        <NandosCarousel {...content} />
      </div>

      {/* Desktop Grid */}
      <p className="benefits-description-desktop">
        {content.description + " " + content.descriptionPrompt}
      </p>
      <div className="benefits-flex-container">
        <div className={classNames("benefit-grid-container")}>
          {content.paragraph.map((benefit, i) => {
            return (
              <div className="benefit-container">
                {i > 0 && <hr />}
                <img src={benefit.icon}></img>
                <p className="description">{benefit.description}</p>
              </div>
            );
          })}
        </div>
      </div>
      <div className="benefits-bottom-section">
        <AnimationButton route={content.buttonLink} label={content.buttonText} color="#005087" />
        <img
          src={bottomLeftPattern}
          aria-label="bottom-left-pattern"
          className="benefits-bottom-left-pattern no-pointer"
        />
      </div>
    </div>
  );
};

export default BenefitsSection;
