import React from "react";
import "./GrowingInNandosSection.scss";
import nandocaImageDesktop from "../../assets/img/growingInNandosSectionAssets/nandocasImage.png";
import nandocaImageMobile from "../../assets/img/growingInNandosSectionAssets/nandocasImageMobile.png";
import periFarmImageDesktop from "../../assets/img/growingInNandosSectionAssets/periFarmImage.png";
import restaurantNandocasImageDesktop from "../../assets/img/growingInNandosSectionAssets/restaurantNandocasImage.png";
import AnimationButton from "../AnimationButton/AnimationButton";
import AwardsSection from "../AwardsSection/AwardsSection";
import AwardsCarousel from "../AwardsCarousel/AwardsCarousel";

type Props = {
  content: TGrowingNandosSectionContent;
};

const GrowingInNandosSection = ({ content }: Props) => {
  return (
    <>
      <div className="growing-in-nandos-section">
        <div className="nandoca-image-container">
          <img
            src={nandocaImageDesktop}
            className="nandoca-image-desktop desktop-only"
            aria-label="nandocaImageDesktop"
          ></img>
          <img
            src={nandocaImageMobile}
            className="nandoca-image-mobile mobile-only"
            aria-label="nandocaImageMobile"
          ></img>
          <div className="awards-section-container">
            <AwardsSection />
          </div>
        </div>
        <div>
          <AwardsCarousel />
        </div>
        <div className="growing-in-nandos-container">
          <div>
            <h4>{content.title}</h4>
            <p>{content.paragraph1}</p>
            <div className="button-container">
              <AnimationButton route={content.buttonLink} label={content.buttonText} />
            </div>
            <img
              src={periFarmImageDesktop}
              aria-label="periFarmImage"
              className="peri-farm-image-desktop"
            ></img>
          </div>
          <div>
            <img
              src={restaurantNandocasImageDesktop}
              aria-label="restaurantNandocasImage"
              className="restaurant-nandocas-image-desktop"
            ></img>
            <p>
              {content.paragraph2}
              <br />
              <br />
              {content.paragraph3}
            </p>
          </div>
        </div>
        <div className="just-starting-out-container">
          <div>
            <h4>{content.startingOutTitle}</h4>
            <p>{content.startingOutDescription}</p>
            <div className="button-container">
              <AnimationButton
                route={content.startingOutButtonLink}
                label={content.startingOutButtonText}
                color="#FFCD41"
              />
            </div>
          </div>
          <div>
            <h4>{content.startingOutTitle1}</h4>
            <p>{content.startingOutDescription1}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default GrowingInNandosSection;
