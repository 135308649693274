export const GrowingInNandosContent: TGrowingNandosSectionContent = {
  title: "Growing in Nando's",
  paragraph1:
    "Growing Others is at the heart of Nando’s – something we all share responsibility for. Everyday, it’s the generosity of the people around us and the time that they give us that develops our skills and confidence, for work and for life. We believe that it’s our duty to pay this forward, by giving others the opportunities we’ve been given. Above all, we know that when we grow others, we grow ourselves and in turn we all play our part in growing Nando’s.",
  buttonText: "View our roles",
  buttonLink: "/our-roles",
  paragraph2:
    "Above all, it’s the people that make the chicken and so it’s important we make Nando’s an inclusive, fun place to work with plenty of opportunities to grow. It doesn’t matter how long you’ve been part of the family or where you are in your career. Your journey is unique to you, but we’ll make sure you know what opportunities and support is in place to help you grow.",
  paragraph3:
    "We have clear career pathways for all operational roles, from Buddy to Regional Managing Director. We’re proud of our talent and have a strong focus on internal progression so you can be certain that if you have what it takes to advance in our business, we’ll be talking to you about it and doing everything we can to help you along the way. You can expect regular feedback from everyone around you as well as reviews about not only what you are doing but how you are doing it.",
  startingOutTitle: "Just starting out?",
  startingOutDescription:
    "We believe that anyone can shape and control their own destiny, when they know what they need to do to be successful and have someone to show them where to start. Check out our Early Careers page to find out more.",
  startingOutButtonText: "View early careers",
  startingOutButtonLink: "/our-roles/early-careers",
  startingOutTitle1: "What’s in it for me?",
  startingOutDescription1:
    "We want to inspire you to be the best you can be – at Nando’s and out in the big wide world. When we talk about growth, we’re talking about more than just helping you do your job, we’re also talking about giving you the chance to increase your knowledge, grow your skills and get the experience that you can use throughout your life. From time management and problem solving, to building resilience and developing relationships, we’re here to help you become the very best person you can be.",
};
