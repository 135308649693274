import * as icons from "../../assets/img/icons/benefits";

export const BenefitsContent: TBenefitsSectionContent = {
  title: "Benefits",
  description:
    "Since the beginning we’ve wanted Nando’s to be a different place to work. Naturally, rewards will depend on your role, but we’ll clear that up with you before you join!",
  descriptionPrompt: "Some of our benefits for everyone include:",
  buttonLink: "/our-roles",
  buttonText: "View our roles",
  paragraph: [
    {
      icon: icons.Phone,
      description: "Free meals and a discount of up to 40% for you and up to 5 friends.",
    },
    {
      icon: icons.Number5,
      description:
        "We offer a paid 4-week sabbatical for every 5 years of continual service. It gives our people the chance to do something for you, whatever that may be!",
    },
    {
      icon: icons.Flame,
      description: "Great discounts platform available to everyone to help your money go further.",
    },
    {
      icon: icons.Steps,
      description: "Internal development programmes to support your career development.",
    },
    {
      icon: icons.Nandoca,
      description: "Refer a friend incentive scheme.",
    },
    {
      icon: icons.Casa,
      description: "Access to the Nando's pension scheme (UK Only).",
    },
    {
      icon: icons.SpeechBubble,
      description:
        "Access to Life Assurance and Care First, our confidential wellbeing and support line.",
    },
    {
      icon: icons.SouthAfrica,
      description:
        "We make sure each year is filled with fun, whether it’s team trips, annual conferences, family fun days, networking events or festive parties.",
    },
  ],
};
